/* Courtesy of Gurdeep Osahan */
/* http://bootsnipp.com/Gurdeep%20Osahan */
@import "../../assets/scss/argon-dashboard-pro-react";


.spinner {
    background: rgba(255,255,255,0.8) repeat scroll 0 0;
    height: 100%;
    left: 0;
    margin: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    transition: all 1s;
}
.bokeh {
    border: 0.01em solid rgba(150, 150, 150, 0.1);
    border-radius: 50%;
    font-size: 100px;
    height: 1em;
    list-style: outside none none;
    margin: 0 auto;
    position: relative;
    top: 35%;
    width: 1em;
    z-index: 2147483647;
}
.bokeh li {
    border-radius: 50%;
    height: 0.2em;
    position: absolute;
    width: 0.2em;
}
.bokeh li:nth-child(1) {
    animation: 1.13s linear 0s normal none infinite running rota, 3.67s ease-in-out 0s alternate none infinite running opa;
    background: $default none repeat scroll 0 0;
    left: 50%;
    margin: 0 0 0 -0.1em;
    top: 0;
    transform-origin: 50% 250% 0;
}
.bokeh li:nth-child(2) {
    animation: 1.86s linear 0s normal none infinite running rota, 4.29s ease-in-out 0s alternate none infinite running opa;
    background: $primary none repeat scroll 0 0;
    margin: -0.1em 0 0;
    right: 0;
    top: 50%;
    transform-origin: -150% 50% 0;
}
.bokeh li:nth-child(3) {
    animation: 1.45s linear 0s normal none infinite running rota, 5.12s ease-in-out 0s alternate none infinite running opa;
    background: $success none repeat scroll 0 0;
    bottom: 0;
    left: 50%;
    margin: 0 0 0 -0.1em;
    transform-origin: 50% -150% 0;
}
.bokeh li:nth-child(4) {
    animation: 1.72s linear 0s normal none infinite running rota, 5.25s ease-in-out 0s alternate none infinite running opa;
    background: $teal none repeat scroll 0 0;
    margin: -0.1em 0 0;
    top: 50%;
    transform-origin: 250% 50% 0;
}
@keyframes opa {
    12% {
        opacity: 0.8;
    }
    19.5% {
        opacity: 0.88;
    }
    37.2% {
        opacity: 0.64;
    }
    40.5% {
        opacity: 0.52;
    }
    52.7% {
        opacity: 0.69;
    }
    60.2% {
        opacity: 0.6;
    }
    66.6% {
        opacity: 0.52;
    }
    70% {
        opacity: 0.63;
    }
    79.9% {
        opacity: 0.6;
    }
    84.2% {
        opacity: 0.75;
    }
    91% {
        opacity: 0.87;
    }
}

@keyframes rota {
    100% {
        transform: rotate(360deg);
    }
}
